import React, { useEffect, useState } from "react";
import './PdfViewer.css';

export interface PdfViewerState{
    loaded : boolean;
}

/**
 * @author Christophe Convert
 * 
 */
export const PdfViewer = (props: { fileName: string, backupUrl?: string, adobeViewId?: string, adobeClientId?: string}) : JSX.Element => {
    const { fileName, backupUrl, adobeViewId, adobeClientId } = props;
    const [pdfViewerState, setPdfViewerState] = useState<PdfViewerState>({
        loaded: false
      } as PdfViewerState);

    const getHeight = () : number => {
        return ( 70 * window.screen.availHeight / 100 );
    };

    const load = async (w: any) : Promise<void> => {
        if(!adobeClientId || ! adobeViewId) return Promise.resolve();
        return new Promise<void>((resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) => {
            try{
                
                const script = document.createElement("script");
                script.src = "https://documentcloud.adobe.com/view-sdk/main.js";
                script.async = true;
                document.body.appendChild(script);
                

                console.log("--add adobe_dc_view_sdk.ready listener");
                document.addEventListener("adobe_dc_view_sdk.ready", function () {
                    console.log("--event received");
                    var adobeDCView = new w.AdobeDC.View({clientId: adobeClientId, divId: adobeViewId});
                    adobeDCView.previewFile({
                        content:{location: {url: `${process.env.PUBLIC_URL}/pdf/${fileName}`.toString()}},
                        metaData:{fileName: fileName}
                    }, {showDownloadPDF: true, showPageControls: true});
                    resolve();
                });
            }
            catch(error : any){
                reject(error);
            }
        });
    };

    const { loaded } = pdfViewerState;

    useEffect(() => {
        load(window)
        .then((error : any) => {
            setPdfViewerState({loaded : true});
        })
        .catch((error : any) => {
            console.log('Failed to load pdf.');
          console.log(error);
        });
    }, []);

    return (
        <>
            { adobeClientId ?
              <div id={adobeViewId}></div>
              :
              <object data={`${process.env.PUBLIC_URL}/pdf/${fileName}`.toString()} height={getHeight()} className="pdf-viewer" type="application/pdf" >
                <embed src={backupUrl} height={getHeight()} className="pdf-viewer" />
              </object>
            }
        </>

    )
}