
import React , { useEffect, useState }  from "react";
import { NFT } from "./nft/NFT";
import { Search } from "../search/Search";
import { clearCaches, loadSummerOffers, Offer } from "../../service/hostel/Hostel";
import dayjs, { Dayjs } from "dayjs";
import BigNumber from 'bn.js';
import { SUMMER_START_DATE, ZERO } from "../../constant/constant";
import { BookingView } from "./booking/view/BookingView";
import { Scroll } from "../../component/scroll/Scroll";
import { Text } from "../../component/text/Text";
import { getScrollHeight } from "../../utils/ScreenUtils";
import { useTranslation } from "react-i18next";
import './MarketPlace.css';

/**
 * @author Christophe Convert
 * 
 */
export interface MarketPlaceState{
  arrivalDate: Dayjs; 
  departureDate: Dayjs;
  offers : Offer[];
  prices : BigNumber[];
};

export interface MarketPlaceBookingState{
  offerToBook? : Offer;
};

export type TRANSACTION_STATUS = 'None' | 'Error' | 'Received' | 'Succeded';

/**
 * @author Christophe Convert
 * 
 */
export const MarketPlace = () : JSX.Element => {
    const {t} = useTranslation('common');
    const [marketPlaceState, setMarketPlaceState] = useState<MarketPlaceState>({
      offers : [],
      prices : [ZERO,ZERO],
      arrivalDate : dayjs(SUMMER_START_DATE),
      departureDate : dayjs(SUMMER_START_DATE).add(1, 'day')
    } as MarketPlaceState);

    const [marketPlaceBookingState, setMarketPlaceBookingState] = useState<MarketPlaceBookingState>({} as MarketPlaceBookingState);

    useEffect(() => {
      loadState();
    }, []); 

    const reloadCaches = () => {
      setInterval(async () => {
          console.log('---------------clearCaches()');
          clearCaches();
      },30000);
    };

    const loadState = async () : Promise<void> => {
      try{
        const offers : Offer[] = await loadSummerOffers(100, 0);
        const prices : BigNumber[] = await computePrices(offers);
        reloadCaches();
        setMarketPlaceState({...marketPlaceState, offers, prices });
      }
      catch(e : any){
        console.error('Error while loading Filters: ',e);
      }
    };

    const computePrices = async (offers : Offer[]) : Promise<BigNumber[]> => {
      let min : BigNumber = ZERO;
      let max : BigNumber = ZERO;
      offers.forEach(o => {
        const {nightUnitPrice} = o;
        if(min.eq(ZERO) || nightUnitPrice.lt(min)){
          min = nightUnitPrice;
        }
        if(max.eq(ZERO) || nightUnitPrice.gt(max)){
          max = nightUnitPrice;
        }
      });
      return [min, max];
    };

    const onOfferSelected = (offerToBook : Offer) : void => {
      setMarketPlaceBookingState({...marketPlaceBookingState, offerToBook });
    };

    const cancelSelection = () : void => {
      setMarketPlaceBookingState({...marketPlaceBookingState, offerToBook: undefined });
    };


    const getChunkSize = () : number => {
      if(window.screen.availWidth <= 819){
        return 1;
      }
      else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
        return 2;
      }
      else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
        return 3;
      }
      else{
        return 4;
      }
    };

    const { arrivalDate, departureDate, offers, prices } = marketPlaceState;
    const { offerToBook } = marketPlaceBookingState;

    let chunkSize = getChunkSize(); 
    let chunks : Offer[][] = [];
    let j = 0;
    for (let i = 0; i < offers.length; i += chunkSize) {
      let chunk : Offer[] = [];
      for (let j = 0; (j < chunkSize && i+j < offers.length); j += 1) {
        chunk.push(offers[i+j]);
      }
      chunks.push(chunk);
    }
    
    return(
        <>
        <div className="marketplace-all-container">
          <Text content={t('page.marketplace.temporary')} style={{marginTop: '0.5vh'}} size='md' theme='light' />
          <Search prices={prices} onFilter = {(arrivalDate: Dayjs, departureDate: Dayjs, offers : Offer[]) => {
            setMarketPlaceState({...marketPlaceState, arrivalDate, departureDate, offers});
          }}/>
          <Scroll maxHeight={getScrollHeight('85vh','85vh')}>
              <>
              { chunks.map((chunktokenIds, i) => (
                <div className="marketplace-row" key={`marketplace-row-${i}`.toString()}>
                    { chunktokenIds.map((offer,j) => (
                      <NFT 
                        key={`nft-${offer.offerId}`.toString()}
                        offer={offer}
                        arrivalDate={arrivalDate}
                        departureDate={departureDate}
                        onOfferSelected={onOfferSelected}
                      />
                    ))}
                </div>
              ))}
            </>
          </Scroll>
        </div>
        <BookingView offer={offerToBook} arrivalDate={arrivalDate} departureDate={departureDate} cancel={cancelSelection}/>
        </>
    );

};
