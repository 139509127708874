
import React from "react";
import { NavPanel, NavPanelItem } from "../component/nav-panel/NavPanel";
import { Perspective } from "./perspective/Perspective";
import { MarketPlace } from "./marketplace/MarketPlace";
import { Goals } from "./goals/Goals";
import { Presale } from "./presale/Presale";
import { WhitePaper } from "./whitepaper/WhitePaper";
import './Front.css';

/**
 * @author Christophe Convert
 * 
 */

const navPanelItems : NavPanelItem[] = [
  {id : '1', title : 'page.goals.title', path : '/goals', backgroundImage : 'green-texture-1.png', backgroundRepeat : 'repeat'},
  {id : '2', title : 'page.presale.title', path : '/presale', backgroundImage : 'green-texture-2.png', backgroundRepeat : 'repeat'},
  {id : '3', title : 'page.marketplace.title', path : '/app', backgroundImage : 'green-texture-1.png', backgroundRepeat : 'repeat'},
  {id : '4', title : 'page.whitepaper.title', path : '/whitepaper', backgroundImage : 'green-texture-3.png', backgroundRepeat : 'repeat'},
  {id : '5', title : 'page.tokenomics.title', path : '/tokenomics', backgroundImage : 'green-texture-2.png', backgroundRepeat : 'repeat'}
];

const Front = () => {

  const logo = () : string => {
    if(window.screen.availWidth <= 399){
      return "jurakko_logo_blanc-01.png";
    }
    else if(window.screen.availWidth >= 400 && window.screen.availWidth <= 819){
      return "jurakko_logo_blanc-01.png";
    }
    else if(window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      return "jurakko_logo_blanc-02.png";
    }
    else{
      return "jurakko_logo_blanc-02.png";
    }
  };
  
  return (
    <NavPanel items = {navPanelItems} logo={logo} >
      <Goals />
      <Presale />
      <MarketPlace />
      <WhitePaper />
      <Perspective />
    </NavPanel>
  );
}

export default Front;