import BigNumber from 'bn.js';

import { ETH_NETWORK } from './config/NetworkConfig';
import { POLYGONSCAN_CHAIN_ID, POLYGONSCAN_TX_URL, UNISWAP_URL } from './config/Config';
import { EMPTY } from '../../constant/constant';

// Web3.js 4.x import
const { Web3 } = require('web3');

/**
 * @author Christophe Convert
 * 
 */
export interface Web3Service {
    loaded : boolean;
    accounts : string[];
    observers : ((accounts : string[]) => void)[];
    web3 : any;
};

const onChainChanged = (w : any) => {
    w.ethereum.on('chainChanged', (chainId : any) => {
        // We recommend reloading the page, unless you must do otherwise
        w.location.reload();
    });
}

const getProvider = async (w : any) : Promise<any> => { 
    console.log("getProvider ...");
    console.log(w.ethereum);
    if(w.ethereum !== undefined){
        onChainChanged(w);
        return Promise.resolve(w.ethereum);
    }
    return Promise.reject();
};

export const hasProvider = async ()  : Promise<boolean> => {
    try{
        const provider = await getProvider(window);
        return provider;
    }
    catch(e : any){
        console.log('No provider: ',e);
        return false;
    }
};

const WEB3_SERVICE : Web3Service = { web3 : undefined, accounts : [], observers : [], loaded : false};

export const web3 = async (connect : boolean) : Promise<any> => {
    if(WEB3_SERVICE.web3 && (WEB3_SERVICE.loaded || !connect)){
        return WEB3_SERVICE.web3;
    }
    console.log('************WEB3***********');
    const providerConfig = ETH_NETWORK.get(POLYGONSCAN_CHAIN_ID);
    if(!providerConfig){
        throw new Error('no network config for chain id: '+POLYGONSCAN_CHAIN_ID);
    }
    const { chainId, providerURL } = providerConfig;
    const provider = connect ? await getProvider(window) : providerURL;

    WEB3_SERVICE.web3 = new Web3(provider);
    WEB3_SERVICE.web3.eth.setProvider(provider);
    WEB3_SERVICE.web3.providers = WEB3_SERVICE.web3.providers || {};
    WEB3_SERVICE.web3.providers.http = WEB3_SERVICE.web3.providers.http || {};
    WEB3_SERVICE.web3.providers.http[chainId] = WEB3_SERVICE.web3.providers.http[chainId] || {};
    // WEB3_SERVICE.web3.providers.http[chainId].providerSecret= providerSecret;

    WEB3_SERVICE.web3.providers.http[chainId].providerURL= providerURL;
    WEB3_SERVICE.loaded=connect;
    return WEB3_SERVICE.web3;
};

export interface ContractConfig {
    abi : any;
    address : string;
};


export const contract = async (contractConfig : ContractConfig, connect : boolean) : Promise<any> => {
    const { abi, address } = contractConfig;
    const WEB3 =  await web3(connect);
    return new WEB3.eth.Contract(abi, address);
};

export const onError = (error : any) : string => {
    if(error && error.message){
        const msg = error.message;
        const status = error.status;
        if(msg.match(/User denied/) || msg.match(/User cancelled/) || status === 'Aborted'){
            return 'Transaction was rejected.';
        }
        else if(msg.match(/TransactionBlockTimeoutError/)){
            return 'The transaction is still waiting. The network may be saturated.';
        }
        else if(msg.match(/gas required exceed allowance/)){
            return 'Transaction was rejected because of missing funds.';
        }
        else if(msg.match(/Invalid "from" address/)){
            return 'Wallet is invalid.';
        }
        return 'Transaction failed.';
    }
    else{
        return 'Transaction has been rejected because of problem with your wallet.';
    }
};

interface Task {
    callable : () => Promise<any>;
    resolve : (value : any) => void;
    reject : (value? : any) => void;
}

const CALL_TASKS : Task[] = [];

const CALL_TASK_WORKER = setInterval(async () => {
    if(CALL_TASKS.length>0){
        const task : Task | undefined = CALL_TASKS.shift();
        if(task){
            const { callable, resolve, reject } = task;
            try{
                console.log('---------WT');
                const value = await callable();
                resolve(value);
            }
            catch(e : any){
                reject(e);
            }
        }
    }
},250);


export const call = async (contractConfig : ContractConfig, method : string, params: any[], sendParams: any) : Promise<any> => {
    try{
        const crct = await contract(contractConfig, false);
        if(!crct.methods || !crct.methods[method]){
            return Promise.reject(new Error('No method '+method+' on contract'));
        }
        
        return new Promise<any>((resolve, reject) => {
            CALL_TASKS.push({
                callable : () => crct.methods[method](...params).call(sendParams),
                resolve,
                reject
            });
        });
        //return await crct.methods[method](...params).call(sendParams);
    }
    catch(error : any){
        console.log(error);
        return Promise.reject(error);
    }
};

export interface SendResult {
    txHash : string;
    error : string;
};


/*
export const send = async (contractConfig : ContractConfig, method : string, params: any[], sendParams: any, transactionSucceded: (txHash : string) => void, transactionFailed: (txHash : string, error : string) => void) : Promise<void> => {
    const result : Promise<SendResult> = contractSend(contractConfig, method, params, sendParams);
    return result.then((res : SendResult) => {
        transactionSucceded(res.txHash);
    }).catch((res : SendResult) => {
        transactionFailed(res.txHash,res.error);
    });
};

export const contractSend = async (contractConfig : ContractConfig, method : string, params: any[], sendParams: any) : Promise<SendResult> => {
    let crct : any = null;
    try{
        crct = await contract(contractConfig, true);
        if(!crct.methods || !crct.methods[method]){
            return Promise.r1eject({txHash : EMPTY, error : 'No method '+method+' on contract'});
        }
    }
    catch(error : any){
        console.log(error);
        return Promise.reject({txHash : EMPTY, error : EMPTY});
    }

    return new Promise<SendResult>(async (resolve, reject) => {
        try{
            console.log(sendParams);
            await crct.methods[method](...params).send(sendParams)
            .on('transactionHash',(e: any) => { console.log(e)})
            .on('confirmation', (confirmNumber : number, result : any) => {
                console.log('confirm, '+method+' : '+confirmNumber);
                if(confirmNumber === 0){
                    //unsubscribe(listenerTab[0]);
                    if(result.status){
                        resolve({txHash : result.transactionHash, error : EMPTY});
                    }
                    else{
                        reject({txHash : result.transactionHash, error : onError(EMPTY)});
                    }
                }
            })
            .on('error', (error : any) =>  {
                reject({txHash : EMPTY, error : onError(error)});
            });
        }
        catch(error : any){
            console.log(error);
            reject({txHash : EMPTY, error : onError(error)});
        }
    });
};
*/

export const send = async (contractConfig : ContractConfig, method : string, params: any[], sendParams: any, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) : Promise<void> => {
    let crct : any = null;
    try{
        crct = await contract(contractConfig, true);
        crct.handleRevert = true;
        
        if(!crct.methods || !crct.methods[method]){
            transactionFailed(EMPTY,'No method '+method+' on contract');
            return Promise.reject();
        }
    }
    catch(error : any){
        console.log(error);
        transactionFailed(EMPTY,EMPTY);
        return Promise.reject();
    }

    try{
        console.log(crct);
        const methodEvent = crct.methods[method](...params).send(sendParams)
        methodEvent.on('transactionHash',(e: any) => { console.log(e)})
            .on('confirmation', (confirm : any, result : any) => {
                console.log('confirm, '+method+' : ',confirm);
                console.log(confirm.confirmations);
                console.log(confirm.confirmations.toString());
                if(confirm && (confirm.confirmations.toString() == '1')){
                    const transactionHash = confirm && confirm.receipt && confirm.receipt.transactionHash ? confirm.receipt.transactionHash : EMPTY;
                    transactionSucceded(transactionHash);
                }
            })
            .on('error', (error : any) =>  {
                transactionFailed(EMPTY,onError(error));
            });

            await methodEvent;
    }
    catch(error : any){
        console.log(error);
        transactionFailed(EMPTY,onError(error));
    }
};

export const gasPriceInWei = (valueInGwei: string) : string => {
   return Web3.utils.toWei(valueInGwei, 'gwei');
}

export const connectedAccounts = () : string[] => {
    return WEB3_SERVICE.accounts;
};


export const connectedAccount = () : string => {
    return WEB3_SERVICE.accounts.length>0 ? WEB3_SERVICE.accounts[0] : EMPTY;
};

export const connect = async () : Promise<string[]> => {
    try{
        const provider = await getProvider(window);
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        WEB3_SERVICE.accounts = accounts;
        return Promise.resolve(accounts);
    }
    catch(e: any){
        return Promise.reject(e);
    }
};

export const subscribeConnectEvent = ( observer : (accounts : string[]) => void) => {
    WEB3_SERVICE.observers.push(observer);
};

export const connectAndNotify = async () : Promise<string[]> => {
    try{
        const provider = await getProvider(window);
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        WEB3_SERVICE.accounts = accounts;
        WEB3_SERVICE.observers.forEach(observer => {
            observer(accounts);
        });
        return Promise.resolve(accounts);
    }
    catch(e: any){
        return Promise.reject(e);
    }
}

export const disconnectAndNotify = async () : Promise<void> => {
    try{
        const provider = await getProvider(window);
        await provider.request({
            method: "eth_requestAccounts",
            params: [
              {
                eth_accounts: {}
              }
            ]
        }); 
        
        WEB3_SERVICE.accounts = [];
        WEB3_SERVICE.observers.forEach(observer => {
            observer([]);
        });
        return Promise.resolve();
    }
    catch(e: any){
        return Promise.reject(e);
    }
}


// ------------------------------------ POLYGONSCAN --------------------------------------------------------------

export const getEtherscanLink = (txHash: string) => {
    return `${POLYGONSCAN_TX_URL}${txHash ? txHash : ''}`.toString();
}

// ------------------------------------ UNISWAP --------------------------------------------------------------

export const getUniswapLink = (id: BigNumber) => {
    return `${UNISWAP_URL}`.toString();
}

// ------------------------------------ ERC20 Token --------------------------------------------------------------

export const addERC20 = async (address : string, symbol: string, decimals: number, image: string) : Promise<void> => {
  const w =  await await getProvider(window);

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.

    const wasAdded = await w.request({
        method: 'wallet_watchAsset',
        params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
            address, // The address that the token is at.
            symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals, // The number of decimals in the token
            image // A string url of the token logo
        },
      },
    });
  } catch (error: any) {
    console.log(error);
  }
};

/*
// ------------------------------------ ERC1155 Token --------------------------------------------------------------

export const getERC1155UnitPriceInETH = async (tokenIds: BigNumber[]) : Promise<BigNumber[]> => {
    return call({abi : ERC1155_MARKETPLACE_ABI, address : ERC1155_MARKETPLACE_ADDRESS }, 'getUnitPrice', [tokenIds], {}).then((values :string[]) => values.map(v => new BigNumber(v)));
};

export const getERC1155Quantities = async (tokenIds: BigNumber[]) : Promise<BigNumber[]> => {
    return call({abi : ERC1155_MARKETPLACE_ABI, address : ERC1155_MARKETPLACE_ADDRESS }, 'balances', [tokenIds], {}).then((values :string[]) => values.map(v => new BigNumber(v)));
};

export const buyERC1155 = async (address : string, amountInEth : BigNumber, tokenIds : BigNumber[], tokenAmounts : BigNumber[], transactionSucceded: (txHash : string) => void, transactionFailed: (txHash : string) => void) : Promise<any> => {
    return send({abi : ERC1155_MARKETPLACE_ABI, address : ERC1155_MARKETPLACE_ADDRESS }, 'buy', [tokenIds, tokenAmounts], { from : address, value : amountInEth }, transactionSucceded, transactionFailed);
};
*/

