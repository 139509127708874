import BigNumber from 'bn.js';
import { DECIMALS_15, DECIMALS_18, DECIMALS_2, ZERO } from '../../constant/constant';
import { JMap, values } from '../../models/Collection';
import { JURAKKO_ASSET_MARKETPLACE_ABI, JURAKKO_ERC20_ABI, JURAKKO_ERC20_ADDRESS } from '../crypto/config/Config';
import { call, send } from '../crypto/Crypto';
import { JURAKKO_ASSET_MARKETPLACE_ADDRESS } from '../crypto/config/Config';

export const ASSET_IDS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
export const assetPictureId = (id : number) : string => `hostel-${id}.jpeg`.toString();

//-----------------------------------ASSET SERVICES---------------------------------------------------

export interface AssetFilters{
    categories : string[];
    places: string[];
    prices : BigNumber[];
};

export const INITIAL_ASSET_FILTERS : AssetFilters = {
    categories : [],
    places: [],
    prices : []
};

export interface AssetWithParts{
    asset : Asset;
    assetParts : AssetParts;
};

const ASSET_FILTER = [INITIAL_ASSET_FILTERS];
const ASSET_MAP : JMap<AssetWithParts> = {};
const ASSET_OBSERVERS : ((assetFilters : AssetFilters) => void)[] = [];

export const subscribeAssets = ( observer : (assetFilters : AssetFilters) => void) => {
    ASSET_OBSERVERS.push(observer);
    notify();
};

export const notifyAsset = ( asset : Asset, assetParts : AssetParts) => {
    ASSET_MAP[asset.id.toString()] = { asset, assetParts };
    notify();
};

const notify = () => {
    const assetWithParts = [...values(ASSET_MAP)];
    const categories : string[] = [];
    const places : string[] = [];
    const prices : BigNumber[] = [];
    if(assetWithParts.length==0){
        return;
    }
    assetWithParts.forEach(a => {
        if(!categories.includes(a.asset.category)){
            categories.push(a.asset.category);
        }
        if(!places.includes(a.asset.country)){
            places.push(a.asset.country);
        }
        const min_ = min(a.assetParts.unitPrices);
        const max_ = max(a.assetParts.unitPrices);

        if(prices.length===0){
            prices.push(min_);
            prices.push(max_);
        }
        else{
            if(min_.lt(prices[0])){
                prices[0]=min_;
            }
            if(max_.gt(prices[1])){
                prices[1]=max_;
            }
        }
    });

    if(JSON.stringify(categories)==JSON.stringify(ASSET_FILTER[0].categories) && JSON.stringify(places)==JSON.stringify(ASSET_FILTER[0].places)){
        return;
    }
    ASSET_FILTER[0] = {categories, places, prices};

    ASSET_OBSERVERS.map(obs => obs({categories, places, prices}));
};

export const loadAssetFilters = () : AssetFilters => {
    return ASSET_FILTER[0];
}

const min = (elements : BigNumber[]) => {
    let m = elements[0];
    elements.forEach(e => {
        m = BigNumber.min(m,e);
    });
    return m;
};

const max = (elements : BigNumber[]) => {
    let m = elements[0];
    elements.forEach(e => {
        m = BigNumber.max(m,e);
    });
    return m;
};

const matchPrice = (a : AssetWithParts, assetFilters : AssetFilters) : boolean => {
    const { prices } = assetFilters;
    const min_ = prices[0];
    const max_ = prices[1];
    return a.assetParts.unitPrices.find(p => p.gte(min_) && p.lte(max_)) !== undefined;
};

export const match = (a : AssetWithParts, assetFilters : AssetFilters) : boolean => {
    const {categories, places} = assetFilters;
    const ok = categories.includes(a.asset.category) 
        && places.includes(a.asset.country)
        && matchPrice(a, assetFilters);
    return ok;
};

export const idsFromAssetFilters = (assetFilters : AssetFilters) : number[] => {
    return [...values(ASSET_MAP)].filter((a : AssetWithParts) => match(a, assetFilters)).map(a => a.asset.id.toNumber());
};

//-----------------------------------ASSET Icons ---------------------------------------------------

export const assetCategoryIcon = (category : string) : string => {
    if(category=='Tiny house'){
        return 'fa-solid fa-campground';
    }
    if(category=='Forest cabin'){
        return 'fa-solid fa-tent';
    }
    if(category=='Mountain refuge'){
        return 'fa-solid fa-mountain-city';
    }
    return 'fa-solid fa-house';
};

export const assetPlaceIcon = (place : string) : string => {
    if(place=='Tiny house'){
        return 'fa-solid fa-cabin';
    }
    if(place=='Forest cabin'){
        return 'fa-solid fa-tent';
    }
    if(place=='Mountain refuge'){
        return 'fa-solid fa-mountain-city';
    }
    return 'fa-solid fa-house';
};

//-----------------------------------ASSET UTILS---------------------------------------------------

export const formatAmount = (value : BigNumber) : string => {
    const amt = value ? value.div(DECIMALS_18) : ZERO;
    const cent = value ? value.sub(amt.mul(DECIMALS_18)).div(DECIMALS_15) : ZERO;
    return `${amt}.${cent}`;
};

export const iPart = (value : BigNumber) : string => {
    const amt = value ? value.div(DECIMALS_18) : ZERO;
    return `${amt}`;
};

export const percent = (value : BigNumber) : string => {
    const amt = value ? value.div(DECIMALS_2) : ZERO;
    const cent = value ? value.sub(amt.mul(DECIMALS_2)) : ZERO;
    return `${amt}.${cent}`;
};

export const formatAddress = (account : string, size : number) : string => {
    if(account.length<size){
        return account;
    }
    const len = size/2;
    return `${account.substring(0,len)}...${account.substring(account.length - len + 3, account.length)}`.toString();
};

// ------------------------------------ JURAKKO ASSET MARKETPLACE --------------------------------------------------------------

export interface ID {
    account: string,
    idType: string,
    id: string
};

export interface AssetParts {
    owners: string[],
    quantities: BigNumber[],
    unitPrices: BigNumber[],
    locked: boolean[]
};

export interface Asset {
    projectId: BigNumber,
    id: BigNumber,

    title: string,
    country: string,
    area: string,
    category: string,
    description: string,

    longitude: BigNumber,
    lattitude: BigNumber,

    unitPrice: BigNumber,
    supply: BigNumber,
    whiteList: string[],

    rwaId: string,
    deliveryTimestamp: BigNumber
};

export const getPrice = async (assetId : number, indexes: BigNumber[], quantities: BigNumber[]) : Promise<BigNumber> => {
    return call({abi : JURAKKO_ASSET_MARKETPLACE_ABI, address : JURAKKO_ASSET_MARKETPLACE_ADDRESS }, 'getPrice', [assetId, indexes, quantities], {})
    .then((value :string) => new BigNumber(value));
};

export const getId = async (account : string) : Promise<ID> => {
    return call({abi : JURAKKO_ASSET_MARKETPLACE_ABI, address : JURAKKO_ASSET_MARKETPLACE_ADDRESS }, 'getId', [account], {});
};

export const viewAsset = async (assetId : number) : Promise<Asset> => {
    return call({abi : JURAKKO_ASSET_MARKETPLACE_ABI, address : JURAKKO_ASSET_MARKETPLACE_ADDRESS }, 'viewAsset', [assetId], {})
    .then((asset : any) => {
        const {projectId, id, title, country, area, category, description, longitude, lattitude, unitPrice, supply, whiteList, rwaId, deliveryTimestamp} = asset;

        return {
            projectId: new BigNumber(projectId),
            id: new BigNumber(id),
        
            title, 
            country, 
            area, 
            category, 
            description,
            
            longitude: new BigNumber(longitude),
            lattitude: new BigNumber(lattitude),
        
            unitPrice: new BigNumber(unitPrice),
            supply: new BigNumber(supply),
            whiteList,
        
            rwaId,
            deliveryTimestamp: new BigNumber(deliveryTimestamp)
        };
    });
};

export const viewParts = async (assetId : number) : Promise<AssetParts> => {
    return call({abi : JURAKKO_ASSET_MARKETPLACE_ABI, address : JURAKKO_ASSET_MARKETPLACE_ADDRESS }, 'viewParts', [assetId], {})
    .then((assetParts : any) => {
        const { owners, quantities, unitPrices, locked } = assetParts;
        
        return { 
            owners, 
            quantities : quantities.map((qty : string) => new BigNumber(qty)), 
            unitPrices : unitPrices.map((unitPrice : string) => new BigNumber(unitPrice)),
            locked
        };
    });
};

export const approveAssetMarketPlace = async (address : string, price : BigNumber, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_ERC20_ABI, address : JURAKKO_ERC20_ADDRESS }, 'approve', [JURAKKO_ASSET_MARKETPLACE_ADDRESS, price], { from : address }, transactionSucceded, transactionFailed);
};

export const buy = async (address : string, assetId : number, indexes : number[], quantities : number[], unitPrice : BigNumber, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_ASSET_MARKETPLACE_ABI, address : JURAKKO_ASSET_MARKETPLACE_ADDRESS }, 'buy', [assetId, indexes, quantities, unitPrice], { from : address }, transactionSucceded, transactionFailed);
};

export const claimNFT = async (address : string, assetId : number, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_ASSET_MARKETPLACE_ABI, address : JURAKKO_ASSET_MARKETPLACE_ADDRESS }, 'lock', [assetId, true], { from : address }, transactionSucceded, transactionFailed);
};

export const setApprovalForAllToAssetMarketPlace = async (address : string, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_ERC20_ABI, address : JURAKKO_ERC20_ADDRESS }, 'setApprovalForAll', [JURAKKO_ASSET_MARKETPLACE_ADDRESS, true], { from : address }, transactionSucceded, transactionFailed);
};

export const depositNFT = async (address : string, assetId : number, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_ASSET_MARKETPLACE_ABI, address : JURAKKO_ASSET_MARKETPLACE_ADDRESS }, 'lock', [assetId, false], { from : address }, transactionSucceded, transactionFailed);
};
