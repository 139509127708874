

export const largeScreen = () : boolean => {
    return window.screen.availWidth >= 1020;
};

export const mediumScreen = () : boolean => {
  return window.screen.availWidth >= 820;
};

export const getSmallMargin = () : string => {
  if(window.screen.availWidth <= 819){
      return '3vh';
  }
  else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      return '1vh';
  }
  else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
      return '5vh';
  }
  else{
      return '5h';
  }
};

export const getMargin = () : string => {
  if(window.screen.availWidth <= 819){
      return '20vw';
  }
  else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      return '10vw';
  }
  else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
      return '5vw';
  }
  else{
      return '5vw';
  }
};

export const getLargeMargin = () : string => {
  if(window.screen.availWidth <= 819){
    return '20vw';
  }
  else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
    return '14vw';
  }
  else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
    return '10vw';
  }
  else{
    return '10vw';
  }
};

export const getScrollHeight = (largeScreenHeight : string, smallScreenHeight : string) : string => {
    if(window.screen.availWidth <= 819){
      return largeScreenHeight;
    }
    else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      return smallScreenHeight;
    }
    else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
      return smallScreenHeight;
    }
    else{
      return smallScreenHeight;
    }
};

