import BigNumber from 'bn.js';
import { WEBSITE_URL, JURAKKO_ERC20_DECIMALS, JURAKKO_ERC20_IMAGE, JURAKKO_ERC20_MARKETPLACE_ABI, JURAKKO_ERC20_MARKETPLACE_ADDRESS, JURAKKO_ERC20_SYMBOL, JURAKKO_ERC20_ADDRESS } from "../crypto/config/Config";
import { addERC20, call, gasPriceInWei, send } from "../crypto/Crypto";

// On Polygon :
// Matic = 0.0675 
// Gas fee standard : 89 GWei
// Cost  = 600000 * 89 * 10E-9 * 0,675 = 0.036 $
const GAS = 1000000;

// On Polygon :
// Matic = 0.0675 
// Gas fee standard : 89 GWei
// Cost  = 9000000 * 89 * 10E-9 * 0,675 = 0.54 $
const GAS_LIMIT = 9000000;

//------------------------------------------------------------- POLYGON L2 -------------------------------------------------------------

export const getJKOUnitPriceInPOL = async () : Promise<any> => {
    return call({abi : JURAKKO_ERC20_MARKETPLACE_ABI, address : JURAKKO_ERC20_MARKETPLACE_ADDRESS }, 'getUnitPrice', [], {});
};

export const buyJKO = async (address : string, amountInPol : BigNumber, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error: string) => Promise<void>) : Promise<any> => {
    const gasPrice = gasPriceInWei('30');
    return send({abi : JURAKKO_ERC20_MARKETPLACE_ABI, address : JURAKKO_ERC20_MARKETPLACE_ADDRESS }, 'buy', [], { from : address, value : amountInPol.toString(), gasPrice: gasPrice }, transactionSucceded, transactionFailed);
};

export const addJKO = async () => {
    return addERC20(JURAKKO_ERC20_ADDRESS, JURAKKO_ERC20_SYMBOL, JURAKKO_ERC20_DECIMALS, `${WEBSITE_URL}/${JURAKKO_ERC20_IMAGE}`);
};

