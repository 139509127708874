import React from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from "@mui/x-charts/BarChart";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Scroll } from "../../component/scroll/Scroll";
import { Text } from "../../component/text/Text";
import { useTranslation } from "react-i18next";
import { Pane } from "../../component/pane/Pane";
import { newId } from "../../utils/IdUtils";
import { getLargeMargin, getMargin, largeScreen } from "../../utils/ScreenUtils";
import './Perspective.css';

/**
 * @author Christophe Convert
 * 
 */
export const Perspective = () : JSX.Element => {
    const {t} = useTranslation('common');

    const height = largeScreen() ? '50vh' : '20vh';

    const pieWidth = largeScreen() ? 500 : 500;

    // In millions
    const totalSupply = 100;

    // Shares in Percent
    const earlyInvestors = 12;
    const team = 10;
    const compagny = 10;
    const counsel = 3;
    const founders = 15;
    const exchange = 50;

    const monthPercent = 2;

    const pieData = [
      { id: 0, value: earlyInvestors, label: t('page.tokenomics.earlyInvestors') },
      { id: 1, value: team, label: t('page.tokenomics.team') },
      { id: 2, value: compagny, label: t('page.tokenomics.compagny') },
      { id: 3, value: counsel, label: t('page.tokenomics.counsel') },
      { id: 4, value: founders, label: t('page.tokenomics.founders') },
      { id: 5, value: exchange, label: t('page.tokenomics.exchange') },
    ];

    const nbToken = (categoryPercent : number, month : number) : number => {
      return categoryPercent * (100 - month * monthPercent) * totalSupply / 10000;
    };

    const exchangeTokensAtMonth = (month : number) : number => {
      const initialTotalLocked = nbToken(earlyInvestors,0) + nbToken(team,0) + nbToken(compagny,0) + nbToken(counsel,0) + nbToken(founders,0);
      const totalLocked = nbToken(earlyInvestors,month) + nbToken(team,month) + nbToken(compagny,month) + nbToken(counsel,month) + nbToken(founders,month);
      return nbToken(exchange,0) + (initialTotalLocked - totalLocked);
    };

    const months = [1,2,3,4,5,6,7,8,9,10];

    const categoryTokens = (categoryPercent : number) : number[] => months.map(q => nbToken(categoryPercent, q));

    const exchangeTokens = () : number[] => months.map(q => exchangeTokensAtMonth(q));

    const valueFormatter = (value: number | null) => `${value}B`;

    const series = [
      { data: categoryTokens(earlyInvestors), stack: 'Locked Tokens', label: t('page.tokenomics.earlyInvestors'), valueFormatter },
      { data: categoryTokens(team), stack: 'Locked Tokens', label: t('page.tokenomics.team'), valueFormatter },
      { data: categoryTokens(compagny), stack: 'Locked Tokens', label: t('page.tokenomics.compagny'), valueFormatter },
      { data: categoryTokens(counsel), stack: 'Locked Tokens', label: t('page.tokenomics.counsel'), valueFormatter },
      { data: categoryTokens(founders), stack: 'Locked Tokens', label: t('page.tokenomics.founders'), valueFormatter },
      { data: exchangeTokens(), stack: 'Exchanges', label: t('page.tokenomics.exchange'), valueFormatter },
    ];

    const monthAxisLabels = () : string[] => months.map(q => `M${q}`);

    const timeline = [1,2,3,4,5,6,7,8,9,10,11,12];

    return (
        <Scroll maxHeight="90vh">
            <Text content={t('page.tokenomics.title')} style={{ marginTop : getMargin(), marginBottom : getMargin()}} size="lg" theme="light" />
            
            {largeScreen() ? 
                  <Pane row={true}>
                    <Pane ratio="50" style={{ height, backgroundColor : 'white'}}>
                      <PieChart
                          series={[
                              {
                                data: pieData,
                              },
                          ]}
                          width={pieWidth}
                          height={200}
                      />
                    </Pane>
                    <Pane ratio="50" style={{ height, backgroundColor : 'white'}}>
                      <Text content={t('page.tokenomics.tokenomic0')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.tokenomic1')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.tokenomic2')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.tokenomic3')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.tokenomic4')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.tokenomic5')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.tokenomic6')} size="md" theme="dark" />
                    </Pane>
                  </Pane>
                :
                <>
                  <Pane ratio="100" style={{ backgroundColor : 'white'}}>
                    <Text content={t('page.tokenomics.tokenomic0')} style={{ marginTop : getMargin() }} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.tokenomic1')} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.tokenomic2')} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.tokenomic3')} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.tokenomic4')} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.tokenomic5')} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.tokenomic6')} style={{ marginBottom : getMargin() }} size="md" theme="dark" />
                    <PieChart
                      series={[
                          {
                            data: pieData
                          },
                      ]}
                      height={200}
                      width={pieWidth}
                    />
                    <Text content="" style={{ marginBottom : getMargin() }} size="md" theme="dark" />
                  </Pane>
                </>
            }
            {largeScreen() ? 
                  <Pane row={true} style={{ paddingTop : getMargin(), paddingBottom : getMargin(), backgroundColor : '#a4efcb'}}>
                    <Pane ratio="50" style={{ height, backgroundColor : '#a4efcb'}}>
                      <Text content={t('page.tokenomics.liquidity1')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.liquidity2')} size="md" theme="dark" />
                      <Text content={t('page.tokenomics.liquidity3')} size="md" theme="dark" />
                    </Pane>
                    <Pane ratio="50" style={{ height, backgroundColor : '#a4efcb'}}>
                    <BarChart
                      xAxis={[{ scaleType: 'band', data: monthAxisLabels() }]}
                      yAxis={[{ label : 'number of token (B)' }]}
                      series={series}
                    />
                    </Pane>
                  </Pane>
                :
                <>
                  <Pane ratio="100" style={{ backgroundColor : '#a4efcb'}}>
                    <Text content={t('page.tokenomics.liquidity1')} style={{ marginTop : getMargin() }} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.liquidity2')} size="md" theme="dark" />
                    <Text content={t('page.tokenomics.liquidity3')} style={{ marginBottom : getMargin() }} size="md" theme="dark" />
                    <BarChart
                      xAxis={[{ scaleType: 'band', data: monthAxisLabels() }]}
                      yAxis={[{ label : 'number of token (B)' }]}
                      series={series}
                      height={400}
                    />
                    <Text content="" style={{ marginBottom : getMargin() }} size="md" theme="dark" />
                  </Pane>
                </>
            }
            <Text content={t('page.tokenomics.roadmaptitle1')} style={{ marginTop : getMargin(), marginBottom : getMargin()}} size="lg" theme="light" />

            <Pane ratio="100" style={{ paddingTop : getMargin(), paddingBottom : getMargin(), backgroundColor : 'white'}}>
              <Text content={t('page.tokenomics.roadmaptitle2')} style={{ marginTop : getMargin(), marginBottom : getMargin() }} size="md" theme="dark" />
              <Timeline position="alternate">
                {
                  timeline.map( d => (
                    <TimelineItem key={`timeline_item-${d}-${newId()}`.toString()}>
                      <TimelineOppositeContent >
                        <Text content={t(`page.tokenomics.date${d}`.toString())} size="md" bold={true} theme="dark" />
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent color="text.secondary">
                        <Text content={t(`page.tokenomics.roadmap${d}`.toString())} size="md" theme="dark" />
                      </TimelineContent>
                    </TimelineItem>
                  ))
                }
              </Timeline>
            </Pane>
        </Scroll>
    )
}