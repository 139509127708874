import { post } from "../HttpEndpoint";
import BigNumber from 'bn.js';
import { ApolloClient, InMemoryCache, gql, ApolloQueryResult } from '@apollo/client'
import { DECIMALS_15, DECIMALS_18, DECIMALS_2, SUMMER_START_TIMESTAMP, ZERO } from "../../constant/constant";
import { POLYGONSCAN_TX_URL, UNISWAP_URL } from "../crypto/config/Config";

import { JURAKKO_ERC20_ADDRESS, JURAKKO_BOOKING_ABI, JURAKKO_BOOKING_ADDRESS, JURAKKO_BILL_ADDRESS, JURAKKO_BILL_ABI, JURAKKO_ERC20_ABI } from '../crypto/config/Config';
import { send } from "../crypto/Crypto";

//---------------------------------- UTILS---------------------------------------------------

export const formatAmount = (value : BigNumber) : string => {
    const amt = value ? value.div(DECIMALS_18) : ZERO;
    const cent = value ? value.sub(amt.mul(DECIMALS_18)).div(DECIMALS_15) : ZERO;
    return `${amt}.${cent}`;
};

export const iPart = (value : BigNumber) : string => {
    const amt = value ? value.div(DECIMALS_18) : ZERO;
    return `${amt}`;
};

export const percent = (value : BigNumber) : string => {
    const amt = value ? value.div(DECIMALS_2) : ZERO;
    const cent = value ? value.sub(amt.mul(DECIMALS_2)) : ZERO;
    return `${amt}.${cent}`;
};

export const formatAddress = (account : string, size : number) : string => {
    if(account.length<size){
        return account;
    }
    const len = size/2;
    return `${account.substring(0,len)}...${account.substring(account.length - len + 3, account.length)}`.toString();
};

// ------------------------------------ POLYGONSCAN --------------------------------------------------------------

export const getEtherscanLink = (txHash: string) => {
    return `${POLYGONSCAN_TX_URL}${txHash ? txHash : ''}`.toString();
}

// ------------------------------------ UNISWAP --------------------------------------------------------------

export const getUniswapLink = () => {
    return `${UNISWAP_URL}`.toString();
}

//------------------------------------------------------------- ETHEREUM L1 / POLYGON L2 -------------------------------------------------------------

export const approveBookingContract = async (address : string, price : BigNumber, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_ERC20_ABI, address : JURAKKO_ERC20_ADDRESS }, 'approve', [JURAKKO_BOOKING_ADDRESS, price.toString()], { 'from' : address }, transactionSucceded, transactionFailed);
};

export const bookAReservation = async (address : string, id : BigNumber, offerId : BigNumber, arrivalTimestamp : BigNumber, departureTimestamp : BigNumber, reservationPackage : string,transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_BOOKING_ABI, address : JURAKKO_BOOKING_ADDRESS }, 'bookAReservation', [id.toString(), offerId.toString(), arrivalTimestamp.toString(), departureTimestamp.toString(), reservationPackage], { from : address, gas: 1000000, gasPrice: "10000000000" }, transactionSucceded, transactionFailed);
};

export const cancelAReservation = async (address : string, id : BigNumber, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_BOOKING_ABI, address : JURAKKO_BOOKING_ADDRESS }, 'cancelAReservation', [id.toString()], { from : address, gas: 1000000, gasPrice: "10000000000" }, transactionSucceded, transactionFailed);
};

export const mintBill = async (address : string, reservationId : BigNumber, transactionSucceded: (txHash : string) => Promise<void>, transactionFailed: (txHash : string, error : string) => Promise<void>) => {
    return send({abi : JURAKKO_BILL_ABI, address : JURAKKO_BILL_ADDRESS }, 'mint', [reservationId.toString()], { from : address, gas: 1000000, gasPrice: "10000000000" }, transactionSucceded, transactionFailed);
};

//------------------------------------------------------------- THE GRAPH ----------------------------------------------------------------------------

const THE_GRAPH_QUERY_URL = process.env.REACT_APP_THE_GRAPH_QUERY_URL ? process.env.REACT_APP_THE_GRAPH_QUERY_URL : "";
const client = new ApolloClient({  uri: THE_GRAPH_QUERY_URL,  cache: new InMemoryCache()});

export interface Hostel {
    id: string,
  
    hostelId: BigNumber,
    country: string,
    area: string,
    longitude: BigNumber,
    lattitude: BigNumber,
    hostelName: string,
    category: string,
  
    hostelAddress: string,
    societyName: string,
    account: string,
    description: string,
    pictureId: BigNumber,
    wifi: boolean,
    vatForTenThousand: BigNumber,
    feeForTenThousand: BigNumber
};

export const loadHostels = async (first : number, skip : number, category : string[], country : string[], area : string[]) : Promise<Hostel[]>=> {

    let clause = '';
    if(category.length>0 || country.length>0 || area.length>0){
        clause = `, where : { 
                    ${category.length? 'category_in : $category':''}
                    ${country.length? ',country_in : $country':''}
                    ${area.length? ',area_in : $area':''} 
                }`;
    }

    const hostelQuery = `
        query GetHostels($first: Int, $skip: Int, $category: [String], $country: [String], $area: [String]) {
            hostels(first: $first, skip: $skip ${clause}) {
                id

                hostelId
                country
                area
                longitude
                lattitude
                hostelName
                category
              
                hostelAddress
                societyName
                account
                description
                pictureId
                wifi
                vatForTenThousand
                feeForTenThousand
            }
        }`; 

    let variables : any = {first, skip};
    variables  = category.length? {...variables, category } : variables;
    variables  = country.length? {...variables, country } : variables;
    variables  = area.length? {...variables, area } : variables;
    
    return client.query({    query: gql(hostelQuery),    variables })
        .then((result : ApolloQueryResult<any>) => {
            if(result.data && result.data.hostels){
                const hostels = result.data.hostels;
                return hostels.map((hostel : any)=> ({
                    id : hostel.id,
                    hostelId : new BigNumber(hostel.id),
                    country : hostel.country,
                    area : hostel.area,
                    longitude : new BigNumber(hostel.longitude),
                    lattitude : new BigNumber(hostel.lattitude),
                    hostelName : hostel.hostelName,
                    category : hostel.category,
                    
                    hostelAddress : hostel.hostelAddress,
                    societyName : hostel.societyName,
                    account : hostel.account,
                    description : hostel.description,
                    pictureId : hostel.pictureId,
                    wifi : hostel.wifi,
                    vatForTenThousand : new BigNumber(hostel.vatForTenThousand),
                    feeForTenThousand : new BigNumber(hostel.feeForTenThousand),
                } as Hostel));
            }
            return [];
        });
};

export interface Offer {
    id: string,
    offerId: BigNumber,
    hostelId: BigNumber,
  
    timestamp: BigNumber,
    country: string,
    area: string,
    longitude: BigNumber,
    lattitude: BigNumber,
    hostelName: string,
    hostelCategory: string,
    hostelAddress: string,
    societyName: string,
    account: string,
    description: string,
    pictureId: BigNumber,
    wifi: boolean,
    vatForTenThousand: BigNumber,
    feeForTenThousand: BigNumber,
  
    offerCategory: string,
    nightUnitPrice: BigNumber,
    periodTimestampStart: BigNumber,
    periodTimestampEnd: BigNumber,
  
    quantity: BigNumber,
    available: BigNumber
};

export const loadSummerOffers = (first : number, skip : number) : Promise<Offer[]> => {
    return loadOffers(first, skip, [], [], [], [], SUMMER_START_TIMESTAMP, []);
};

export const loadOffers = (first : number, skip : number, hostelCategory : string[], country : string[], area : string[], offerCategory : string[], timestamp : BigNumber, unitPrices : BigNumber[]) : Promise<Offer[]> => {

    let clause = '';
    if(hostelCategory.length>0 || country.length>0 || area.length>0 || offerCategory.length > 0 || timestamp && timestamp.gt(ZERO)){
        clause = `, where : {  
            ${hostelCategory.length? ' hostelCategory_in : $hostelCategory':''}
            ${country.length? ' ,country_in : $country':''}
            ${area.length? ' ,area_in : $area':''} 
            ${offerCategory.length? ' ,offerCategory_in : $offerCategory':''}
            ${(timestamp && timestamp.gt(ZERO))? ' ,timestamp:  $timestamp':''}
        }`;
    }

    const offerQuery = `
        query GetOffers($first: Int, $skip: Int, $orderBy: String, $orderDirection: String, $hostelCategory: [String], $country: [String], $area: [String], $offerCategory: [String], $timestamp: BigInt) {
            offerAvailabilities(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection ${clause}) {
                id
                offerId
                hostelId
              
                timestamp
                country
                area
                longitude
                lattitude
                hostelName
                hostelCategory
                hostelAddress
                societyName
                account
                description
                pictureId
                wifi
                vatForTenThousand
                feeForTenThousand
              
                offerCategory
                nightUnitPrice
                periodTimestampStart
                periodTimestampEnd
              
                quantity
                available
            }
        }`; 

    let variables : any = {first, skip, orderBy: 'hostelId', orderDirection: 'asc'};
    variables  = hostelCategory.length? {...variables, hostelCategory } : variables;
    variables  = country.length? {...variables, country } : variables;
    variables  = area.length? {...variables, area } : variables;
    variables  = offerCategory.length? {...variables, offerCategory } : variables;
    variables  = (timestamp && timestamp.gt(ZERO))? {...variables, timestamp: timestamp.toNumber() } : variables;
    
    return client.query({ query: gql(offerQuery), variables })
        .then((result : ApolloQueryResult<any>) => {
            if(result.data && result.data.offerAvailabilities){
                console.log(result.data);

                const offerAvailabilities = result.data.offerAvailabilities;
                return offerAvailabilities.map((offerAvailability : any)=> ({
                    id : offerAvailability.id,
                    offerId : new BigNumber(offerAvailability.offerId),
                    hostelId : new BigNumber(offerAvailability.hostelId),

                    timestamp : new BigNumber(offerAvailability.timestamp),
                    country : offerAvailability.country,
                    area : offerAvailability.area,
                    longitude : new BigNumber(offerAvailability.longitude),
                    lattitude : new BigNumber(offerAvailability.lattitude),
                    hostelName : offerAvailability.hostelName,
                    hostelCategory : offerAvailability.hostelCategory,
                    
                    hostelAddress : offerAvailability.hostelAddress,
                    societyName : offerAvailability.societyName,
                    account : offerAvailability.account,
                    description : offerAvailability.description,
                    pictureId : new BigNumber(offerAvailability.pictureId),
                    wifi : offerAvailability.wifi,
                    vatForTenThousand : new BigNumber(offerAvailability.vatForTenThousand),
                    feeForTenThousand : new BigNumber(offerAvailability.feeForTenThousand),

                    offerCategory: offerAvailability.offerCategory,
                    nightUnitPrice: new BigNumber(offerAvailability.nightUnitPrice),
                    periodTimestampStart: new BigNumber(offerAvailability.periodTimestampStart),
                    periodTimestampEnd: new BigNumber(offerAvailability.periodTimestampEnd),
                
                    quantity: new BigNumber(offerAvailability.quantity),
                    available: new BigNumber(offerAvailability.available)
                } as Offer));
            }
            return [];
        });
};

export const loadPeriodOffers = (first : number, skip : number, offerId : BigNumber, timestampStart : BigNumber, timestampEnd : BigNumber) : Promise<Offer[]> => {

    let clause = '';
    if(offerId && offerId.gt(ZERO) || timestampStart && timestampStart.gt(ZERO) || timestampEnd && timestampEnd.gt(ZERO)){
        clause = `, where : {  
            ${(offerId && offerId.gt(ZERO))? ' offerId:  $offerId':''}
            ${(timestampStart && timestampStart.gt(ZERO))? ' ,timestamp_gte:  $timestampStart':''}
            ${(timestampEnd && timestampEnd.gt(ZERO))? ' ,timestamp_lte:  $timestampEnd':''}
        }`;
    }

    const offerQuery = `
        query GetPeriodOffers($first: Int, $skip: Int, $orderBy: String, $orderDirection: String, $offerId: BigInt, $timestampStart: BigInt, $timestampEnd: BigInt) {
            offerAvailabilities(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection ${clause}) {
                id
                offerId
                hostelId
              
                timestamp
                country
                area
                longitude
                lattitude
                hostelName
                hostelCategory
                hostelAddress
                societyName
                account
                description
                pictureId
                wifi
                vatForTenThousand
                feeForTenThousand
              
                offerCategory
                nightUnitPrice
                periodTimestampStart
                periodTimestampEnd
              
                quantity
                available
            }
        }`; 

    let variables : any = {first, skip, orderBy: 'timestamp', orderDirection: 'asc'};
    variables  = offerId && offerId.gt(ZERO)? {...variables, offerId: offerId.toString() } : variables;
    variables  = timestampStart && timestampStart.gt(ZERO)? {...variables, timestampStart: timestampStart.toNumber() } : variables;
    variables  = timestampEnd && timestampEnd.gt(ZERO)? {...variables, timestampEnd: timestampEnd.toNumber() } : variables;
    
    return client.query({ query: gql(offerQuery), variables })
        .then((result : ApolloQueryResult<any>) => {
            if(result.data && result.data.offerAvailabilities){
                console.log(result.data);

                const offerAvailabilities = result.data.offerAvailabilities;
                return offerAvailabilities.map((offerAvailability : any)=> ({
                    id : offerAvailability.id,
                    offerId : new BigNumber(offerAvailability.offerId),
                    hostelId : new BigNumber(offerAvailability.hostelId),

                    timestamp : new BigNumber(offerAvailability.timestamp),
                    country : offerAvailability.country,
                    area : offerAvailability.area,
                    longitude : new BigNumber(offerAvailability.longitude),
                    lattitude : new BigNumber(offerAvailability.lattitude),
                    hostelName : offerAvailability.hostelName,
                    hostelCategory : offerAvailability.hostelCategory,
                    
                    hostelAddress : offerAvailability.hostelAddress,
                    societyName : offerAvailability.societyName,
                    account : offerAvailability.account,
                    description : offerAvailability.description,
                    pictureId : new BigNumber(offerAvailability.pictureId),
                    wifi : offerAvailability.wifi,
                    vatForTenThousand : new BigNumber(offerAvailability.vatForTenThousand),
                    feeForTenThousand : new BigNumber(offerAvailability.feeForTenThousand),

                    offerCategory: offerAvailability.offerCategory,
                    nightUnitPrice: new BigNumber(offerAvailability.nightUnitPrice),
                    periodTimestampStart: new BigNumber(offerAvailability.periodTimestampStart),
                    periodTimestampEnd: new BigNumber(offerAvailability.periodTimestampEnd),
                
                    quantity: new BigNumber(offerAvailability.quantity),
                    available: new BigNumber(offerAvailability.available)
                } as Offer));
            }
            return [];
        });
};


export interface AllFilters {
    id: string,
    countries : string[],
    areas : string[],
    hostelCategories : string[],
    offerCategories : string[]
};

export const loadAllFilters = () : Promise<AllFilters> => {

    const offerQuery = `
        query GetAllFilters($filterId: String) {
            allFilters(id: $filterId){
                id
                countries
                areas
                hostelCategories
                offerCategories
            }
        }`; 

    let variables : any = { filterId: 'FILTERS'};
    
    return client.query({    query: gql(offerQuery),    variables })
        .then((result : ApolloQueryResult<any>) => {
            if(result.data && result.data.allFilters){
                return result.data.allFilters;
            }
            throw new Error('Couldn t load Filters');
        });
};

export interface AreasOnCountry {
    id: string,
    country : string,
    areas : string[]
};

export const loadAreasOnCountry = (countries : string[]) : Promise<AreasOnCountry[]> => {

    const offerQuery = `
        query GetAreasOnCountries($countries: [String]) {
            areasOnCountries(where : {country_in: $countries}){
                id
                country
                areas
            }
        }`; 

    let variables : any = { countries };
    
    return client.query({ query: gql(offerQuery), variables })
        .then((result : ApolloQueryResult<any>) => {
            if(result.data && result.data.areasOnCountries){
                console.log('GetAreasOnCountries: ',result.data.areasOnCountries);
                return result.data.areasOnCountries;
            }
            throw new Error('Couldn t load AreasOnCountries');
        });
};

export interface Booking {
    id: string;

    bookingId: BigNumber;
    hostelId: BigNumber;
    offerId: BigNumber;

    account: string;
    arrivalTimestamp: BigNumber;
    departureTimestamp: BigNumber;
    timestamp: BigNumber;

    nightUnitPrice: BigNumber;
    reservationPackage: string;
};

export interface Cancelation {
    id: string;

    bookingId: BigNumber;
};

export const loadAllBookings = async (first : number, skip : number, offerId : BigNumber, account : string) : Promise<Booking[]> => {

    let clause = '';
    if(offerId && offerId.gt(ZERO) && account && account.trim().length>0){
        clause = `, where : {  
            offerId:  $offerId,
            account:  $account
        }`;
    }

    const bookingQuery = `
        query GetBookings($first: Int, $skip: Int, $orderBy: String, $orderDirection: String, $offerId: BigInt, $account: String) {
            bookingAddReservations(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection ${clause}) {
                id 
                bookingId 
                hostelId 
                offerId 
                account 
                arrivalTimestamp 
                departureTimestamp 
                timestamp 
                nightUnitPrice 
                reservationPackage 
            }
        }`; 

    let variables : any = {first, skip, orderBy: 'timestamp', orderDirection: 'asc'};
    variables  = offerId && offerId.gt(ZERO)? {...variables, offerId: offerId.toString() } : variables;
    variables  = account && account.trim().length>0? {...variables, account } : variables;
    
    return client.query({ query: gql(bookingQuery), variables })
        .then((result : ApolloQueryResult<any>) => {
            if(result.data && result.data.bookingAddReservations){
                console.log(result.data);

                const bookingAddReservations = result.data.bookingAddReservations;
                return bookingAddReservations.map((bookingAddReservation : any)=> ({
                    id : bookingAddReservation.id,

                    bookingId : new BigNumber(bookingAddReservation.bookingId),
                    hostelId : new BigNumber(bookingAddReservation.hostelId),
                    offerId : new BigNumber(bookingAddReservation.offerId),

                    account: bookingAddReservation.account,
                    arrivalTimestamp: new BigNumber(bookingAddReservation.arrivalTimestamp), 
                    departureTimestamp: new BigNumber(bookingAddReservation.departureTimestamp),
                    timestamp: new BigNumber(bookingAddReservation.timestamp), 

                    nightUnitPrice: new BigNumber(bookingAddReservation.nightUnitPrice), 
                    reservationPackage: bookingAddReservation.reservationPackage
                } as Booking));
            }
            return [];
        });
};

export const loadCanceledBookings = async (first : number, skip : number, bookingIds : BigNumber[]) : Promise<Cancelation[]> => {

    let clause = '';
    if(bookingIds){
        clause = `, where : {  
            bookingId_in : $bookingIds
        }`;
    }

    const bookingQuery = `
        query GetCanceledBookings($first: Int, $skip: Int, $bookingIds: [BigInt]) {
            bookingRemoveReservations(first: $first, skip: $skip ${clause}) {
                id 
                bookingId 
            }
        }`; 

    let variables : any = {first, skip};
    variables  = bookingIds ? {...variables, bookingIds: bookingIds.map(b => b.toString()) } : variables;
    
    return client.query({ query: gql(bookingQuery), variables })
        .then((result : ApolloQueryResult<any>) => {
            if(result.data && result.data.bookingRemoveReservations){
                console.log(result.data);

                const bookingRemoveReservations = result.data.bookingRemoveReservations;
                return bookingRemoveReservations.map((bookingRemoveReservation : any)=> ({
                    id : bookingRemoveReservation.id,
                    bookingId : new BigNumber(bookingRemoveReservation.bookingId),
                } as Cancelation));
            }
            return [];
        });
};

export const loadBookings = async (first : number, skip : number, offerId : BigNumber, account : string) : Promise<Booking[]> => {
    try{
        const bookings = await loadAllBookings(first, skip, offerId, account);
        const bookingIds = bookings.map(b => b.bookingId);
        const canceledBookings = await loadCanceledBookings(first, skip, bookingIds);
        const canceledBookingIds = canceledBookings.map(b => b.bookingId.toString());
        return bookings.filter(b => !canceledBookingIds.includes(b.bookingId.toString()));
    }
    catch(e : any){
        console.log('Fail to load bookings ',e);
    }
    return [];
};

export const clearCaches = async () : Promise<void> => {
    client.resetStore();
};

export const netlifyTest =  (api : string) =>  post(api,null,{ name :'Chris'})
    .then(json => {
        console.log("netlifyTest success-----");
        console.log(JSON.stringify(json));
    })
    .catch((e : any) => console.log(e));







