import React from "react";
import { Scroll } from "../../component/scroll/Scroll";
import { Text } from "../../component/text/Text";
import { useTranslation } from "react-i18next";
import { SaleBox } from "../../component/sale-box/SaleBox";
import { Pane } from "../../component/pane/Pane";
import { largeScreen, getMargin, getSmallMargin } from "../../utils/ScreenUtils";
import { JURAKKO_ERC20_ADDRESS } from "../../service/crypto/config/Config";
import './Presale.css';

/**
 * @author Christophe Convert
 * 
 */
export const Presale = () : JSX.Element => {
    const {t} = useTranslation('common');

    const height = largeScreen() ? '70vh' : '75vh';

    return(
        <Scroll maxHeight="90vh">
            <Text content={t('page.presale.subtitle')} style={{ marginTop : getMargin()}} size="lg" theme="light" />
            <Text content={`${t('page.presale.start-date')}  -  ${t('page.presale.end-date')}`} style={{ marginTop : getMargin()}} size="md" theme="light" />
            <Text content={`${t('page.goals.token')} ${JURAKKO_ERC20_ADDRESS}`} style={{ marginBottom : getMargin()}} size="md" theme="light" />
            <Pane row={true} src="images/JKO-banner-6.jpeg" style={{ height, paddingTop : getSmallMargin(), display: 'flex', flexDirection:'column', justifyContent: 'flex-start', alignItems:'center'}}>
                <SaleBox />
            </Pane>
        </Scroll>
    );
};