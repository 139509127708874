import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BigNumber from 'bn.js'
import { connect, hasProvider } from "../../service/crypto/Crypto";
import { Alert, AlertTitle } from "@mui/material";
import { addJKO, buyJKO, getJKOUnitPriceInPOL } from "../../service/presale/PresaleService";
import { EMPTY } from "../../constant/constant";
import { JURAKKO_ERC20_IMAGE, POL_ERC20_IMAGE } from "../../service/crypto/config/Config";
import { Img } from "../img/Img";
import { getMargin, mediumScreen } from "../../utils/ScreenUtils";
import { Input } from "../input/Input";
import { Text } from "../text/Text";
import { RoundButton } from "../button/round/RoundButton";
import { Pane } from "../pane/Pane";
import './SaleBox.css';

type TRANSACTION_STATUS = 'None' | 'No Wallet' | 'No Amount' | 'Error' | 'Received' | 'Succeded';

interface SaleBoxState {
  unitPriceInPol : BigNumber;
  inputPOL : string,
  amountInPOL : BigNumber;
  amountInJKO : BigNumber;
  status : TRANSACTION_STATUS;
  txHash : string;
  message : string;
}

const INITIAL_POL_AMOUNT : number = 2.5;

const computeAmount = (val : number) : BigNumber => {
  return new BigNumber(val*1E9).mul(new BigNumber(10).pow(new BigNumber(9)));
};

export const SaleBox = (props : {}) : JSX.Element => {
    const {t} = useTranslation('common');
    const [saleBoxState, setSaleBoxState] = useState<SaleBoxState>({
      unitPriceInPol : new BigNumber(0),
      inputPOL : `${INITIAL_POL_AMOUNT}`,
      amountInPOL : computeAmount(INITIAL_POL_AMOUNT),
      amountInJKO : new BigNumber(0),
      status : 'None',
      txHash : EMPTY,
      message : EMPTY
    } as SaleBoxState);

    useEffect(() => {
      getJKOUnitPriceInPOL()
      .then(async (unitPrice : string) => {
        computeAmounts(`${INITIAL_POL_AMOUNT}`, new BigNumber(unitPrice));
      })
      .catch((error : any) => console.log('Failed to retrieve unit price.\n',error))
    }, []); 

    const { unitPriceInPol, inputPOL, amountInPOL, amountInJKO, status, txHash, message } = saleBoxState;


    const computeAmounts = async (val : string, unitPriceInPol : BigNumber) : Promise<void> => {
      try{
        const amountInPOL = new BigNumber(parseFloat(val)*1E9).mul(new BigNumber(10).pow(new BigNumber(9)));
        const amountInJKO = unitPriceInPol.isZero() ? new BigNumber(0) : amountInPOL.div(unitPriceInPol)
        setSaleBoxState({...saleBoxState, inputPOL : val, amountInPOL, amountInJKO, unitPriceInPol });
      }
      catch(e : any){
        setSaleBoxState({...saleBoxState, inputPOL : val, amountInPOL : new BigNumber(0), amountInJKO : new BigNumber(0), unitPriceInPol });
      }
    };

    const onPolAmount = async (event : any) : Promise<void> => {
      const val = (event && event.target) ?  event.target.value : EMPTY;
      computeAmounts(val,unitPriceInPol);
    };

    const onBuy = async () : Promise<void> => {
      try{
        const provider= await hasProvider();
        if(amountInPOL.isZero()){
          resetStatus();
          setSaleBoxState({...saleBoxState, status : 'No Amount' });
          return;
        }
        if(!provider){
          resetStatus();
          setSaleBoxState({...saleBoxState, status : 'No Wallet' });
          return;
        }
        const addresses = await connect();
        console.log(addresses[0]);
        buyJKO(addresses[0], amountInPOL, async (txHash : string) : Promise<void> => {
            resetStatus();
            addJKO().finally(() => {
              resetStatus();
              setSaleBoxState({...saleBoxState, status : 'Succeded', txHash, message : EMPTY, inputPOL : '0', amountInPOL : new BigNumber(0), amountInJKO : new BigNumber(0)})
            });
          }, async (txHash : string, message : string) : Promise<void> => {
            resetStatus();
            setSaleBoxState({...saleBoxState, status : 'Error', txHash, message, inputPOL : '0', amountInPOL : new BigNumber(0), amountInJKO : new BigNumber(0)});
        })
        /*
        .then((txHash : any) => {
          setSaleBoxState({...saleBoxState, status : 'Received', txHash, message : EMPTY, inputPOL : '0', amountInPOL : new BigNumber(0), amountInJKO : new BigNumber(0)});
        })
        */
        .catch((error : any) => {
          resetStatus();
          setSaleBoxState({...saleBoxState, status : 'Error', txHash : EMPTY, message: error ? error : EMPTY, inputPOL : '0', amountInPOL : new BigNumber(0), amountInJKO : new BigNumber(0)});
        });
      }
      catch(error2 : any){
        resetStatus();
        setSaleBoxState({...saleBoxState, status : 'Error', txHash : EMPTY, message: error2 ? error2 : EMPTY, inputPOL : '0', amountInPOL : new BigNumber(0), amountInJKO : new BigNumber(0)});
      }
    };
    
    const resetStatus = () => {
      setTimeout(()=> {
        setSaleBoxState({...saleBoxState, status : 'None' });
      },3000);
    };

    const notification = (status : TRANSACTION_STATUS, txHash : string, message : string) : JSX.Element => {
        let formatedTxHash = txHash ? txHash : EMPTY;
        if(txHash && txHash.length > 10){
          formatedTxHash = txHash.substring(0,5)+'...'+txHash.substring(txHash.length - 4, txHash.length);
        }
        
        return(
          <>
          {'None' === status &&
            <Text className="salebox-instructions" content={t('page.sale-box.instructions')} size='md' theme='light' />
          }
          {'No Wallet' === status &&
            <Alert severity="error">
              <AlertTitle>{t('page.sale-box.status.errorTitle')}</AlertTitle>
              {`${t('page.sale-box.status.no-wallet')}`.toString()}
            </Alert>
          }
          {'No Amount' === status &&
            <Alert severity="error">
              <AlertTitle>{t('page.sale-box.status.errorTitle')}</AlertTitle>
              {`${t('page.sale-box.status.no-amount')}`.toString()}
            </Alert>
          }
          {'Error' === status &&
            <Alert severity="error">
              <AlertTitle>{t('page.sale-box.status.errorTitle')}</AlertTitle>
              {`${t('page.sale-box.status.error')} ${formatedTxHash}`.toString()}
            </Alert>
          }
          {'Received' === status &&
            <Alert severity="info">
              <AlertTitle>{t('page.sale-box.status.receivedTitle')}</AlertTitle>
              {`${t('page.sale-box.status.received')} ${formatedTxHash}`.toString()}
            </Alert>
          }
          {'Succeded' === status &&
            <Alert severity="success">
              <AlertTitle>{t('page.sale-box.status.succededTitle')}</AlertTitle>
              {`${t('page.sale-box.status.succeded')} ${formatedTxHash}`.toString()}
            </Alert>
          }
          </>
        );
    };

    const Paybox = (props: {}) : JSX.Element => (         
      <Pane row={false}>
        <Pane row={false} style={{ marginBottom : getMargin()}}>
          {notification(status, txHash, message)}
        </Pane>
        <Pane className="salebox-component-card">
          <div className="salebox-component-card-icon">
            <Img src={JURAKKO_ERC20_IMAGE} className="salebox-component-card-icon" />
          </div>
          <div className="salebox-component-card-body">
            <div className="salebox-component-card-title">
              <Text content={t('page.sale-box.sale-box-title')} size='md' theme='light' />
            </div>
            <div className="salebox-component-card-box">
              <Img src={POL_ERC20_IMAGE} className="crypto-img" />
              <Input type="text" className="salebox-component-card-input" value={inputPOL} onChange={onPolAmount} />
            </div>
            <div className="salebox-component-arrow-card-box">
              <Img src="images/arrow-2.png" className="crypto-arrow-img" />
            </div>
            <div className="salebox-component-card-box">
              <Img src={JURAKKO_ERC20_IMAGE} className="crypto-img" />
              <div className="salebox-component-card-input">
                { amountInJKO.toString() }
              </div>
            </div>
            { (!amountInPOL.isZero() && !amountInJKO.isZero()) &&
              <RoundButton className="salebox-btn-custom" title={t('page.sale-box.sale-box-buy')} txtSize='md' reverse={true} onClick = {onBuy}/>
            }
          </div> 
        </Pane>
      </Pane>
    );

    return (
      <Paybox />
    );
};